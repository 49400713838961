/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic&display=swap');
body, html  {

	background: #005151;
	/* Gradient in Hex
linear-gradient(to right, 
	#2D3F87 0%, 10.504138724928147%, #2B1F23 28.859856724739075%, 72.0431698611037%, #2D3F87 100%);*/


	width: 100vw;
	height: 100vh;
	overflow: hidden;
}
.cursor-pointer{
	cursor: pointer;
}

body * {
	color: black ;
	font-family: 'Open Sans'
	/* color: black !important; */
}



h1,h2,h3,h4,h5,h6 {
	font-family: 'Montserrat';
}

.card-header {
	background: #00ab8e;
	color: white;
}
.card-header * {
color: white!important;
	}
.card {
	border: none;
	box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

}

.spinner-border  {
	margin: 8px;
	color: blue;
}

$primary:#5085D5;
$secondary: #1aadfc;
$success: #0bc674;
$info: #5085D5;
$warning: #ebd408;
$danger: #A41435;
$light: #c8f8f5;
$dark:  #2f1d6b;